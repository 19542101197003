<template>
  <div class="" style="margin-top: 20px; background-color: #fff">
    <div class="search_box">
      <div class="title">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        重点指标
      </div>
      <div class="search_boxs">
        <div class="search">
          <span class="lable">地区： </span>
          <div>
            <provinceSelect
              @change="change"
              ref="provinceSelect"
              @selectTreeShow="selectTreeShow"
              style="width: 160px; font-size: 12px"
            />
          </div>
          <div class="row align-items-center" v-show="register">
            <span style="margin-left: 20px" class="lable">行业： </span>
            <div>
              <selectTree ref="selectTree" isSw="database" isName="database" />
            </div>
          </div>

          <span style="margin-left: 20px" class="lable">时间： </span>
          <el-date-picker
            v-model="value1"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            :picker-options="setDateRange"
            value-format="yyyy-MM"
            :clearable="false"
            size="small"
          >
          </el-date-picker>
        </div>

        <div class="lable-button">
          <el-button
            style="height: 35px; font-size: 14px"
            type="primary"
            @click="fetchData(1)"
            icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            style="height: 35px; color: #1989fa; font-size: 14px"
            plain
            @click="resetData()"
            icon="el-icon-refresh-right"
            >重置</el-button
          >
        </div>
      </div>
    </div>
    <div class="ml-4 mt-2">
      <el-button-group>
        <el-button
          size="medium"
          :type="show_type == 0 ? 'primary' : ''"
          @click="searchClick('0')"
          >金额</el-button
        >
        <el-button
          size="medium"
          :type="show_type == 1 ? 'primary' : ''"
          @click="searchClick('1')"
          >增长率</el-button
        >
      </el-button-group>
    </div>
    <div class="table_report" v-if="tableData.length">
      <el-table
        stripe
        ref="listTable"
        :header-cell-style="{ background: '#e9f3ff', color: '#333' }"
        :data="tableData"
        style="width: 100%; font-size: 16px"
      >
        <el-table-column
          v-for="(item, i) in header"
          :key="i"
          :align="i == 0 ? 'left' : 'right'"
          :prop="item"
          :label="item"
          :width="i == 0 ? '250' : '140'"
          :fixed="i == 0 ? true : false"
        >
        </el-table-column>
      </el-table>
    </div>
    <div v-else class="table_report">
      <div class="not_data">
        <img src="~@/assets/img/NewProject/nodata.svg" />
        <div>该时间段行业经济指标数据缺失，暂无数据</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  importance, //重点指标
  interval_time, //查询时间
} from "@/api/database.js";
export default {
  components: {
    selectTree: require("@/components/selectTree/selectTree.vue").default,
    provinceSelect: require("@/components/provinceSelect/index").default,
  },
  data() {
    return {
      setDateRange: {
        disabledDate: (val) => {
          const disabledTime = this.disabledTime;
          const time = val.getTime();
          const min = new Date(disabledTime[0]).getTime();
          const max = new Date(disabledTime[1]).getTime();
          if (min <= time && max > time) {
            return false;
          } else {
            return true;
          }
        },
      },
      value1: [],
      disabledTime: [],
      tableData: [],
      header: [],
      show_type: "0",
      input: "",
      register: true,
    };
  },

  created() {
    this.setData();
  },
  watch: {
    tableData: {
      handler() {
        this.$nextTick(() => {
          this.$refs.listTable.doLayout();
        });
      },
      deep: true,
    },
  },

  methods: {
    async resetData() {
      await this.$refs.selectTree.setData();
      this.$refs.provinceSelect.regions = "全国";

      this.setData();
    },
    selectTreeShow() {
      this.$refs.selectTree.selectTreeShow();
    },
    change(val) {
      if (val == "全国") {
        this.register = true;
        this.$refs.selectTree.setData();
      } else {
        this.register = false;
        this.$refs.selectTree.setOneData();
      }
    },
    searchClick(val) {
      this.show_type = val;
      this.fetchData();
    },
    // 搜索
    search() {
      this.fetchData();
    },
    // 首次加载
    setData() {
      this.$loadingShow();
      interval_time().then(({ data }) => {
        const startFormat = data.data.start_date.substring(0, 7);
        const endFormat = data.data.end_date.substring(0, 7);
        this.value1 = [startFormat, endFormat];
        this.disabledTime = [startFormat, endFormat];
        setTimeout(() => {
          this.search();
        }, 1000);
      });
    },

    fetchData() {
      const industry_code = this.$refs.selectTree
        ? this.$refs.selectTree.get_industry_code()
        : "";

      importance({
        start_date: this.value1[0] + "-01 00:00:00",
        end_date: this.value1[1] + "-01 00:00:00",
        show_type: this.show_type,
        industry_code: this.register ? industry_code : "",
        region: this.$refs.provinceSelect.regions,
      })
        .then(({ data }) => {
          this.header = data.data.header;
          this.total = data.data.total_counts;
          this.tableData = [];
          for (let i = 0; i < 10; i++) {
            const item = data.data.body[i];
            const obj = {};
            for (let j = 0; j < data.data.header.length; j++) {
              const headerItem = data.data.header[j];
              obj[headerItem] = item[j];
            }
            this.tableData.push(obj);
          }
          this.$loadingHide();
        })
        .catch((err) => {
          this.$loadingHide();
        });
    },
  },
};
</script>
<style lang="less" scoped>
#database_industry {
  .title {
    padding: 20px;
    font-size: 20px;
    font-weight: 550;
    border-bottom: 1px solid rgb(240, 237, 237);
  }

  .search_box {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: #fff;
    padding: 5px;

    .search_boxs {
      display: flex;
      justify-content: space-between;
      padding: 25px 30px 0px 25px;
      .search {
        display: flex;
        align-items: center;
      }
      > .lable {
        color: #515a6e;
        line-height: 31px;
      }
      > .lable-button {
        // position: absolute;
        top: 22px;
        right: 40px;
        button {
          cursor: pointer;
          border-radius: 3px;
          padding: 4px 22px;
          font-size: 12px;
          border: 1px solid #1989fa;
        }
        .reset {
          background: #ffffff;
          color: #1989fa;
          margin-right: 5px;
        }
        .search {
          background: #1989fa;
          color: #fff;
        }
      }
    }
  }
  .table_report {
    background: #fff;
    padding: 15px 30px 30px 30px;
  }

  /deep/.v-page-select {
    display: none !important;
  }
  .not_data {
    img {
      width: 543px;
      height: 281px;
    }

    left: 50%;
    top: 150px;
    transform: translateX(-50%);

    div {
      font-size: 16px;
      text-align: center;
      margin-top: 10px;
      color: #666;
      span {
        color: #1989fa;
      }
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1840px;
  }
}
</style>
