<template>
  <el-select
    size="small"
    style="height: 35px"
    v-model="regions"
    @focus="selectTreeShow()"
  >
    <el-option
      v-for="text in arr"
      :label="text"
      :value="text"
      :key="text"
    ></el-option>
  </el-select>
</template>
<script>
import { regions } from "@/api/common.js";
export default {
  data() {
    return {
      regions: "全国",
      arr: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    selectTreeShow() {
      this.$emit("selectTreeShow");
    },
    fetchData() {
      regions().then(({ data }) => {
        this.arr = data.data;
      });
    },
  },
  watch: {
    regions() {
      this.$emit("change", this.regions);
    },
  },
};
</script>
