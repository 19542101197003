<template>
  <div id="database_industry" class="container">
    <div class="" style="background-color: #fff; border-radius: 5px">
      <div class="title">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        行业经济指标数据库
      </div>
    </div>
    <indeustry_fild></indeustry_fild>
    <indeustry_detail></indeustry_detail>
  </div>
</template>
<script>
export default {
  components: {
    indeustry_fild: require("./indeustry_fild.vue").default,
    indeustry_detail: require("./industry_detail.vue").default,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
#database_industry {
  .title {
    padding: 20px;
    font-size: 20px;
    font-weight: 550;
    border-bottom: 1px solid rgb(240, 237, 237);
  }

  .search_box {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background: #fff;
    padding: 5px;

    .search_boxs {
      display: flex;
      justify-content: space-between;
      padding: 25px 30px 0px 25px;
      .search {
        display: flex;
        align-items: center;
      }
      > .lable {
        color: #515a6e;
        line-height: 31px;
      }
      > .lable-button {
        // position: absolute;
        top: 22px;
        right: 40px;
        button {
          cursor: pointer;
          border-radius: 3px;
          padding: 4px 22px;
          font-size: 12px;
          border: 1px solid #1989fa;
        }
        .reset {
          background: #ffffff;
          color: #1989fa;
          margin-right: 5px;
        }
        .search {
          background: #1989fa;
          color: #fff;
        }
      }
    }
  }
  .table_report {
    background: #fff;
    padding: 15px 30px 30px 30px;
  }

  /deep/.v-page-select {
    display: none !important;
  }
  .not_data {
    img {
      width: 543px;
      height: 281px;
    }

    left: 50%;
    top: 150px;
    transform: translateX(-50%);

    div {
      font-size: 16px;
      text-align: center;
      margin-top: 10px;
      color: #666;
      span {
        color: #1989fa;
      }
    }
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1840px;
  }
}
</style>
